import React from "react";
import { MyTitle, SectionWrapper, TitleStyled } from "./styled";
import Box from "components/layout/Box";
import { Text1 } from "components/core/Typography/text";
import { Icon, icons } from "components/icons/styled";
import { SecondaryBadge } from "components/typography/styled";
import { media } from "views/dataMedia";
import { CardRawSmall } from "views/Cards/Cards";
import { GenericButton } from "components/core/CustomButtons/styled";
import { Link } from "gatsby";


export default function SectionMediaSmall() {
  return (
    <SectionWrapper>
      <Box>
        <TitleStyled>
          AlianZas en los medios
        </TitleStyled>
      </Box>
      <Box
        split={12}>
          {media.filter(item => item.small).map(({ href, title, text, icon, src }) => (
            <a href={href} key={href}>
              <CardRawSmall>
                <MyTitle><SecondaryBadge ><Icon icon={icon} /></SecondaryBadge>{title}</MyTitle>
                { text && <Text1>{text} <Icon icon={icons.right} /></Text1>}
              </CardRawSmall>
            </a>
          ))}
      </Box>
      <Box>
        <Link to='/medios'>
          <GenericButton color='primary'>Ver todos</GenericButton>
        </Link>
      </Box>
    </SectionWrapper>
  );
}
