/*eslint-disable*/
import React from "react";
import SectionMainHero from "./Sections/SectionMainHero";
import { PresentationPageWrapper } from "./styled";
import { Container } from "@material-ui/core";
import SectionAboutSmall from "./Sections/SectionAboutSmall";
import SectionServicesSmall from "./Sections/SectionServicesSmall";
import SectionLinks from "./Sections/SectionLinks";
import SectionFooter from "./Sections/SectionFooter";
import SectionKodo from "./Sections/SectionKodo";
import SectionSpaceSmall from "./Sections/SectionSpaceSmall";
import SectionMediaSmall from "./Sections/SectionMediaSmall";


export default function PresentationPage() {


  return (
    <PresentationPageWrapper>
      <SectionLinks />
      <SectionMainHero />
      <SectionKodo absolute />
      <Container>
        <SectionAboutSmall />
      </Container>
      <Container>
        <SectionMediaSmall />
      </Container>
      <Container>
        <SectionServicesSmall />
      </Container>
      <SectionFooter />
    </PresentationPageWrapper>
  );
}
