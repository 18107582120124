import React, { PureComponent } from "react";
import Slider from "react-slick";
import {
  ArrowStyled,
  SectionWrapper,
  Text1Styled,
  TitleStyled
} from "views/PresentationPage/Sections/styled";

import Box from "components/layout/Box";
import { services } from "views/dataServices"
import { Card1Small } from "views/Cards/Cards";



const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  nextArrow: <ArrowStyled />,
  prevArrow: <ArrowStyled />,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 3,
        rows: 2,
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2,
        rows: 2
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        rows: 1
      }
    }
  ]
}
class SectionServicesSmall extends PureComponent {
  render() {
    return (
      <SectionWrapper>
        <Box md={8} sm={8}>
          <TitleStyled>
            Qué ofrece AlianZas
          </TitleStyled>
        </Box>
        <Slider {...sliderSettings}>
          {services.map(({ title, srcLow }, i) => (
            <a key={i} href='/servicios'>
              <Card1Small src={srcLow} alt={title}>
                <Text1Styled>{ title }</Text1Styled>
              </Card1Small>
            </a>
            ))}
        </Slider>
      </SectionWrapper>
    )
  }
}

export default SectionServicesSmall

