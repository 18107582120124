import React from "react";
import { ImgStyled } from "components/Layout/styled";

export default ({
  children,
  src,
  raised = false,
  circle = false,
  round = false}) => {

  return (
    <ImgStyled
      raised={raised}
      round={!circle && round}
      circle={circle}
      alt={children} src={src} />
  )
}
