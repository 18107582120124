import React from "react";
import { GenericButton } from "components/core/CustomButtons/styled";
import { Link } from "gatsby";
import images from "components/styles/images";
import {
  SectionWrapper,
  TitleStyled
} from "views/PresentationPage/Sections/styled";
import Box from "components/layout/Box";
import { Text1 } from "components/core/Typography/text";
import Img from "components/layout/Img";

export default function SectionAboutSmall() {
  return (
    <SectionWrapper>
      <Box md={8} sm={8}>
        <TitleStyled>Sobre mí</TitleStyled>
        <Text1>
         A lo largo de nuestra vida sufrimos impactos (<b>mis Zas, tortas sin manos del Universo</b>) que nos hace quedarnos colapsadas y quietas o que la adrenalina nos haga salir corriendo hacia una salida...
        </Text1>
      </Box>
      <Box>
        <Link to='/sobremi'>
          <GenericButton color='primary'>leer más</GenericButton>
        </Link>
      </Box>
      <Box md={6} sm={6}>
        <Img round raised src={images.bg.butterfly}>Sobre mí</Img>
      </Box>
    </SectionWrapper>
  )
}