import images from "components/styles/images";
import React from "react";
// @material-ui/core components
import { HeroStyled, LogoHeroStyled } from "views/PresentationPage/Sections/styled";

export default function SectionMainHero() {
  return (
    <HeroStyled image={images.bg.water} height='60' color='rose' intensity='strong' shade={1}>
      <LogoHeroStyled href='/sobremi' />
      <h4>
        Cuidado y cambio social.
      </h4>
    </HeroStyled>
  );
}
